<template>
	<div class="main-contents">
		
		<div class="tit">수요기업 프로젝트 / <span>{{input.corpNm}}</span><img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/></div>
		<div class="search-box">
			<SelectComp type="select" class="" :isAll="true" list="0:모집중,1:수행중,2:수행예정,9:수행완료" title="프로젝트 상태" v-model="input.projectStatusTemp"/>						
			<nbsp/>
			<SelectComp type="select" class="" list="1:프로젝트명,2:프로젝트 담당자명,3:매니저명" v-model="input.searchGubun"/>						
			<nbsp/>
			<InputComp type="text" :placeholder="input.searchGubun == '1' ? '프로젝트명' : input.searchGubun == '2' ? '프로젝트 담당자명' : '매니저명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 prj">
				<colgroup>
					<col width="4%">
					<col width="43%">
					<col width="13%">
					<col width="13%">
					<col width="10%">
					<col width="7%">
					<col width="10%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>프로젝트명</th>
						<th>프로젝트 담당</th>
						<th>하이프로 매니저</th>
						<th>등록일</th>
						<th>모집인원</th>
						<th>프로젝트 상태</th>
					</tr>
				</thead>
				<tbody>

					<tr v-for="(project, index) in projectList" :key="project.projectSeq">
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
						<td class="score name" @click="clickProjectInfo(project.projectSeq)">
							<div class="rc_tag" v-if="project.apprYn != 'Y'">
								<div class="rc_mark_red">미 승 인</div>
							</div>
						{{project.projectNm}} &#9654;
						</td>
						<td class="score name">
							<span v-if="project.corpMgrMberNm" @click="popupCorpMgrMber(project.corpMgrMberSeq)">{{project.corpMgrMberNm}} &#9654;</span>
							<span v-else>-</span>
						</td>
						<td class="score name">
							<span v-if="project.admMgrMberNm" @click="popupAdmMgerMber(project.admMgrMberSeq)">{{project.admMgrMberNm}} &#9654;</span>
							<span v-else>-</span>
						</td>
						<td class="score">{{project.regYyyymmdd}}</td>
						<td class="score">
							<span v-if="project.recrPersCnt">{{project.recrPersCnt}}명</span>
							<spen v-else>-</spen>
						</td>
						<td class="score">
							<span v-if="project.projectStatus == 1"><div class="state pf"></div>수 행 중</span>
							<span v-else-if="project.projectStatus == 4"><div class="state us"></div>제안단계</span>
							<span v-else-if="project.projectStatus == 2"><div class="state ep"></div>수행예정</span>
							<span v-else-if="project.projectStatus == 9"><div class="state cp"></div>수행완료</span>
							<div v-if="(project.recrStartYyyymmdd <= nowYyyymmdd && project.recrEndYyyymmdd >= nowYyyymmdd) && project.recrEndDateDiff >= 0"  class="recruit">+모집중</div>
						</td>
					</tr>
					
					<!-- 등록된 프로젝트가 없을 경우 -->
					<tr v-if="pageInfo.totalRecordCount == 0">
						<td colspan="7" class="none">프로젝트가 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	components: { pagingComp },
	data() {
		return {
			input : {
				corpSeq: '',
				corpNm : '',
				searchGubun : '1',		// 1:프로젝트명, 2:담당자명, 3:매니저명
				projectStatus : [],		// 0:모집중, 1:수행중, 2:수행예정, 9:완료
				projectStatusTemp : '',
				searchKeyword: '',
			},
			
			nowYyyymmdd : '',

			projectList : {},
			pageInfo : {},
		};
	},
	mounted(){

		this.nowYyyymmdd = new Date().format("yyyyMMdd");

		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		this.getList();
	},
	methods: {
        getList(div, isScroll = false){
			if(div) this.input.pageIndex = 1;

			this.input.projectStatus = [];

			if(this.input.projectStatusTemp != '') {
				this.input.projectStatus.push(this.input.projectStatusTemp);
			}

            this.$.httpPost('/api/prj/adm/getProjectList', this.input)
                .then(res => {
                    this.projectList = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}
				})
                .catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
        },   
		
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		clickProjectInfo(projectSeq) {

			var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$router.push({name: 'MEM922M02', query : {projectSeq : projectSeq}, params });

		},	
		
		// 수요기업의 프로젝트 담당자 정보 보기
		popupCorpMgrMber(corpMgrMberSeq) {
			var param = {};
			param.reqMberSeq = corpMgrMberSeq;
			param.div = "pmg"; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},

		// 프로젝트 담당 매니저 정보 보기
		popupAdmMgerMber(admMgrMberSeq) {
			var param = {};
			param.reqMberSeq = admMgrMberSeq;
			param.div = "agt"; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},
		
	},
};
</script>
